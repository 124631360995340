<template>
  <div style="width: 100%; height: 100%; margin: 0; border: 0; padding: 0">
    <canvas v-if="supportWebGL" ref="canvas" style="width: 100%; height: 100%"></canvas>
    <div v-else>
      <slot>
        Your browser does not seem to support
        <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation" style="color: #000"
          >WebGL</a
        >.<br />'
      </slot>
    </div>
  </div>
</template>

<script>
import {
  Object3D,
  Vector2,
  Vector3,
  Group,
  Box3,
  Color,
  Scene,
  Raycaster,
  WebGLRenderer,
  PerspectiveCamera,
  LineBasicMaterial,
  LineSegments,
  EdgesGeometry,
  AmbientLight,
  PointLight,
  HemisphereLight,
  DirectionalLight,
  LinearEncoding,
} from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import util from "./util";

// Функция нужна для определения поддержки webGl передается в качестве условия на отображение canvas
const supportWebGL = (() => {
  try {
    const canvas = document.createElement("canvas");
    return !!(
      window.WebGLRenderingContext &&
      (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
    );
  } catch (e) {
    console.log("webgl");
    return false;
  }
})();

// настройки отображения применяется для опций webgl
const DEFAULT_GL_OPTIONS = {
  // убирает лесенку
  antialias: true,
  alpha: true,
};
export default {
  data() {
    return {
      supportWebGL,
      Vector2,
      getCenter: util.getCenter,
      getSize: util.getSize,
      size: {
        width: this.width,
        height: this.height,
      },
      positions: {},
      object: null,
      raycaster: new Raycaster(),
      mouse: new Vector2(),
      camera: new PerspectiveCamera(45, 1, 0.01, 100000),
      // сцена
      scene: new Scene(),
      // пустой 3д объект
      wrapper: new Object3D(),
      // тоже пустой объект возможно их следует объединить
      pivot: null,
      mouseDown: false,
      renderer: null,
      controls: null,
      composer: null,
      edges: null,
      lines: null,
      outlinePass: null,
      allLights: [],
      surfaces: {},
      surfacesControl: {},
      selectedSurfaces: [],
      groups: {},
      typeOfSelect: "none",
      onceClickedSurface: "",
      clock: typeof performance === "undefined" ? Date : performance,
      reqId: null, // requestAnimationFrame id
      clickColor: { r: 0.674, g: 0.074, b: 0.145 },
      hideColor: { r: 0.7529, g: 0.7529, b: 0.7529 },
    };
  },
  props: {
    // ссылка на модель
    src: {
      type: String,
    },
    // ширина
    width: {
      type: Number,
    },
    // высота
    height: {
      type: Number,
    },
    // расположение
    position: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    // поворот
    rotation: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    // масштаб
    scale: {
      type: Object,
      default() {
        return { x: 1, y: 1, z: 1 };
      },
    },
    // освещение
    lights: {
      type: Array,
      default() {
        return [];
      },
    },
    // позиция камеры
    cameraPosition: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    // поворот камеры
    cameraRotation: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    // верхняя граница камеры???
    cameraUp: {
      type: Object,
    },
    // куда смотрик камера
    cameraLookAt: {
      type: Object,
    },
    // цвет заднего фона
    backgroundColor: {
      default: 0xffffff,
    },
    colorize: {
      type: String,
      default: "",
    },
    // Тип обработки нажатий
    // model старый подход, где обработка нажатий происходит внутри компонента 3д модели
    // component новый подход, где обработка нажатий прописывается внутри компонента
    selectorType: {
      type: String,
      default: "component", // component
    },
    // прозрачность заднего фона
    backgroundAlpha: {
      type: Number,
      default: 1,
    },
    // управление???
    controlsOptions: {
      type: Object,
    },
    // параметр который передается в GLTF Loader для запроса
    crossOrigin: {
      default: "anonymous",
    },
    // выделенные поверхности
    selected: {
      type: Array,
    },
    // header для запроса у gltf
    requestHeader: {
      type: Object,
      default() {
        return {};
      },
    },
    // ???
    outputEncoding: {
      type: Number,
      default: LinearEncoding,
    },
    // параметры для webGl
    glOptions: {
      type: Object,
    },
    // тип выделения поверхностей
    selectType: {
      type: String,
      default: "none", // none, multiple, once
    },
    // id поверхности
    surfaceId: {
      type: String,
      default: "name",
    },
    // активная группа поверхностей
    activeGroup: {
      type: Array,
    },
    // тип stl или gltf
    type: String,
    defaultGroups: Array,
  },
  watch: {
    // следит за src если передается новая ссылка происходит новая отрисовка детали
    src: {
      handler(newValue, oldValue) {
        if (oldValue !== undefined && newValue !== oldValue) {
          this.clearScene();
          this.initScene();
        }
      },
    },
    // можно задать вращение извне
    rotation: {
      deep: true,
      handler(val) {
        if (!this.object) return;
        this.object.rotation.set(val.x, val.y, val.z);
      },
    },
    // при изменении ширины происходит resize
    width: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
    // при изменении высоты происходит resize
    height: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
    // можно задать расположение объекта
    position: {
      deep: true,
      handler(val) {
        if (!this.object) return;
        this.object.position.set(val.x, val.y, val.z);
      },
    },
    // задаем тип выделения поверхности
    // доступны none, once, multiple
    selectType: {
      immediate: true,
      handler(val) {
        this.typeOfSelect = val;
      },
    },
    // selected это массив поверхностей которые мы хотим выделить
    // на данный момент не обрабатывается
    // надо переделать на id группы
    selected: {
      immediate: true,
      handler() {},
    },
    // позволяет управлять масштабом объекта
    scale: {
      deep: true,
      handler(val) {
        if (!this.object) return;
        this.object.scale.set(val.x, val.y, val.z);
      },
    },
    // позволяет менять освещение
    lights: {
      deep: true,
      handler() {
        this.updateLights();
      },
    },
    // если изменился размер
    size: {
      deep: true,
      handler() {
        this.updateCamera();
        this.updateRenderer();
      },
    },
    // можно изменить управление
    controlsOptions: {
      deep: true,
      handler() {
        this.updateControls();
      },
    },
    // если меняется прозрачность заднего фона
    backgroundAlpha() {
      this.updateRenderer();
    },
    // если меняется цвет заднего фона
    backgroundColor() {
      this.updateRenderer();
    },
    defaultGroups: function (val) {
      val.forEach((item, index) => {
        this.groups[index + 1] = {
          surfaces: item.surfaces,
          color: 0xb92a21,
        };
        item.surfaces.forEach((surface) => {
          if (surface && this.surfaces[surface] && this.surfaces[surface].group) {
            this.surfaces[surface].group = index + 1;
          }
        });
      });
      this.$emit("set-group", this.groups);
      this.$store.commit("model/setDefaultGroupState", true);
    },
  },
  computed: {
    hasListener() {
      /* eslint-disable no-underscore-dangle */
      const events = this._events;
      const result = {};
      ["on-mousemove", "on-mouseup", "on-mousedown", "on-click", "on-fullsize"].forEach((name) => {
        result[name] = !!events[name] && events[name].length > 0;
      });
      return result;
    },
  },
  methods: {
    // функция изменения размера поля
    onResize() {
      if ((this.$el && !this.$el.offsetWidth) || (this.$el && !this.$el.offsetHeight)) {
        this.size = {
          width: this.width,
          height: this.height,
        };
      } else {
        this.$nextTick(() => {
          this.size = {
            width: this.$el.offsetWidth,
            height: this.$el.offsetHeight,
          };
        });
      }
    },
    // событие при нажатии мыши
    onMouseDown(event) {
      if (!this.hasListener["on-mousedown"]) return;
      const intersected = this.pick(event.clientX, event.clientY);
      if (this.type === "gltf") {
        // this.setSurface(intersected);
        if (this.selectorType === "model") {
          if (
            intersected &&
            intersected.object &&
            this.activeGroup[this.activeGroup.length - 1] > 0
          ) {
            this.setGroup(intersected.object);
          }
        } else if (this.selectorType === "component") {
          if (intersected && intersected.object) {
            this.surfaceClick(intersected.object[this.surfaceId]);
          }
        }
      }
    },
    surfaceClick(name) {
      this.$store.state.modelV2.surfaceClick(name);
    },
    // событие при движении мыши
    onMouseMove() {},
    setGroup(object) {
      const surface = this.surfaces[object[this.surfaceId]].mesh;
      const name = object[this.surfaceId];
      const active = this.activeGroup[this.activeGroup.length - 1];
      if (!this.groups[active]) {
        this.groups[active] = {
          surfaces: [],
          color: 0xb92a21,
        };
      }
      if (this.surfaces[name].group !== active) {
        surface.material.color.set(this.groups[active].color);
        if (this.surfaces[name].group !== 0) {
          this.groups[this.surfaces[name].group].surfaces = this.groups[
            this.surfaces[name].group
          ].surfaces.filter((item) => item !== name);
        }
        this.surfaces[name].group = active;
        this.groups[active].surfaces.push(name);
      } else {
        surface.material.color.set(this.groups[0].color);
        this.surfaces[object[this.surfaceId]].group = 0;
        this.groups[active].surfaces = this.groups[active].surfaces.filter((item) => item !== name);
      }
      this.$store.commit("model/setSurfaces", this.groups[active].surfaces);
      this.$emit("set-group", this.groups);
    },
    // функция выделения поверхностей
    setSurface(intersected) {
      if (intersected) {
        // объект поверхности
        const surface = intersected.object;
        // уникальное значение поверхности зависит от surfaceId
        const surfaceName = intersected.object[this.surfaceId];
        // объект материала поверхности
        const surfaceMaterial = intersected.object.material;
        // множественный выбор
        if (this.typeOfSelect === "multiple") {
          this.surfacesControl[surfaceName]._colored = !this.surfacesControl[surfaceName]._colored;
          surface._surfaceColor = this.clickColor;
          // если нажали на новую поверхность
          if (this.surfacesControl[surfaceName]._colored) {
            surfaceMaterial.color.set("red");
            this.selectedSurfaces.push(surfaceName);
            this.$emit("on-mousedown", this.selectedSurfaces);
            // если нажали на уже выделенную поверхность
          } else {
            surfaceMaterial.color = this.hideColor;
            this.selectedSurfaces = this.selectedSurfaces.filter((item) => item !== surfaceName);
            this.$emit("on-mousedown", this.selectedSurfaces);
          }
          // в случае единичного выделения
        } else if (this.typeOfSelect === "once") {
          // если нажали на незакрашенную поверхность и ниодна не выбрана
          if (!this.onceClickedSurface) {
            this.onceClickedSurface = surfaceName;
            Object.keys(this.surfacesControl).forEach((surface) => {
              if (this.surfacesControl[surface]._colored && surface !== String(surfaceName)) {
                this.surfaces[surface].material.color = this.surfacesControl[surface]._defaultColor;
                this.surfacesControl[surface]._colored = false;
              }
            });
            if (this.surfacesControl[surfaceName]._color) {
              surfaceMaterial.color = this.surfacesControl[surfaceName]._color;
            } else {
              surfaceMaterial.color = this.surfacesControl[surfaceName]._defaultClickColor;
            }
            this.selectedSurfaces = [];
            this.selectedSurfaces.push(surfaceName);
            this.$emit("on-mousedown", this.selectedSurfaces);
            // если нажали на невыбранную поверхность, а до этого была выбрана другая
          } else if (this.onceClickedSurface && this.onceClickedSurface !== surfaceName) {
            this.surfaces[this.onceClickedSurface].material.color =
              this.surfacesControl[this.onceClickedSurface]._defaultColor;
            this.surfacesControl[this.onceClickedSurface]._colored = false;
            this.surfacesControl[surfaceName]._colored = true;
            if (this.surfacesControl[surfaceName]._color) {
              surfaceMaterial.color = this.surfacesControl[surfaceName]._color;
            } else {
              surfaceMaterial.color = this.surfacesControl[surfaceName]._defaultClickColor;
            }
            this.onceClickedSurface = surfaceName;
            this.selectedSurfaces = [];
            this.selectedSurfaces.push(surfaceName);
            this.$emit("on-mousedown", this.selectedSurfaces);
            // если нажали на уже выбранную поверхность
          } else if (this.onceClickedSurface && this.onceClickedSurface === surfaceName) {
            surfaceMaterial.color = this.hideColor;
            this.surfacesControl[surfaceName]._colored = false;
            this.selectedSurfaces = [];
            this.$emit("on-mousedown", this.selectedSurfaces);
            this.onceClickedSurface = "";
          }
        }
      }
    },
    // функция установки цвета
    setColor(surface, defaultColor) {
      if (this.surfacesControl[surface]._colored) {
        if (this.surfacesControl[surface]._color) {
          return this.surfacesControl[surface]._color;
        }
        return this.surfacesControl[surface]._defaultColor;
      }
      return defaultColor;
    },
    // функция отжатия мышки, ничего не делает, но возвращает событие в родителя
    onMouseUp(event) {
      this.mouseDown = false;
      if (!this.hasListener["on-mouseup"]) return;
      const intersected = this.pick(event.clientX, event.clientY);
      this.$emit("on-mouseup", intersected);
    },
    // функция запуска луча при нажатии
    pick(x, y) {
      if (!this.object) return null;
      const rect = this.$el.getBoundingClientRect();
      let X1 = x;
      let Y1 = y;
      X1 -= rect.left;
      Y1 -= rect.top;
      this.mouse.x = (X1 / this.size.width) * 2 - 1;
      this.mouse.y = -(Y1 / this.size.height) * 2 + 1;
      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersects = this.raycaster.intersectObject(this.object, true);
      return (intersects && intersects.length) > 0 ? intersects[0] : null;
    },
    // функция вызова обновлений
    update() {
      this.updateRenderer();
      this.updateCamera();
      this.updateLights();
      this.updateControls();
    },
    // обновление объекта
    updateModel() {
      const { object } = this;
      if (!object) return;
      const { position } = this;
      const { rotation } = this;
      const { scale } = this;
      object.position.set(position.x, position.y, position.z);
      object.rotation.set(rotation.x, rotation.y, rotation.z);
      object.scale.set(scale.x, scale.y, scale.z);
    },
    // обновление рендера
    updateRenderer() {
      const { renderer } = this;
      renderer.setSize(this.size.width, this.size.height);
      renderer.setPixelRatio(window.devicePixelRatio || 1);
      renderer.setClearColor(new Color(this.backgroundColor).getHex());
      renderer.setClearAlpha(this.backgroundAlpha);
    },
    // функция обновления камеры
    updateCamera() {
      const { camera } = this;
      const { object } = this;
      camera.aspect = this.size.width / this.size.height;
      camera.updateProjectionMatrix();
      if (!this.cameraLookAt && !this.cameraUp) {
        if (!object) return;
        const distance = this.getSize(object).length();
        camera.position.set(this.cameraPosition.x, this.cameraPosition.y, this.cameraPosition.z);
        camera.rotation.set(this.cameraRotation.x, this.cameraRotation.y, this.cameraRotation.z);
        if (
          this.cameraPosition.x === 0 &&
          this.cameraPosition.y === 0 &&
          this.cameraPosition.z === 0
        ) {
          camera.position.z = distance;
        }
        camera.lookAt(new Vector3());
      } else {
        camera.position.set(this.cameraPosition.x, this.cameraPosition.y, this.cameraPosition.z);
        camera.rotation.set(this.cameraRotation.x, this.cameraRotation.y, this.cameraRotation.z);
        camera.up.set(this.cameraUp.x, this.cameraUp.y, this.cameraUp.z);
        camera.lookAt(new Vector3(this.cameraLookAt.x, this.cameraLookAt.y, this.cameraLookAt.z));
      }
    },
    // функуия обновления источников света
    updateLights() {
      this.scene.remove(...this.allLights);
      this.allLights = [];
      this.lights.forEach((item) => {
        if (!item.type) return;
        const type = item.type.toLowerCase();
        let light = null;
        if (type === "ambient" || type === "ambientlight") {
          const color = item.color === 0x000000 ? item.color : item.color || 0x404040;
          const intensity = item.intensity === 0 ? item.intensity : item.intensity || 1;
          light = new AmbientLight(color, intensity);
        } else if (type === "point" || type === "pointlight") {
          const color = item.color === 0x000000 ? item.color : item.color || 0xffffff;
          const intensity = item.intensity === 0 ? item.intensity : item.intensity || 1;
          const distance = item.distance || 0;
          const decay = item.decay === 0 ? item.decay : item.decay || 1;
          light = new PointLight(color, intensity, distance, decay);
          if (item.position) {
            light.position.copy(item.position);
          }
        } else if (type === "directional" || type === "directionallight") {
          const color = item.color === 0x000000 ? item.color : item.color || 0xffffff;
          const intensity = item.intensity === 0 ? item.intensity : item.intensity || 1;
          light = new DirectionalLight(color, intensity);
          if (item.position) {
            light.position.copy(item.position);
          }
          if (item.target) {
            light.target.copy(item.target);
          }
        } else if (type === "hemisphere" || type === "hemispherelight") {
          const skyColor = item.skyColor === 0x000000 ? item.skyColor : item.skyColor || 0xffffff;
          const groundColor =
            item.groundColor === 0x000000 ? item.groundColor : item.groundColor || 0xffffff;
          const intensity = item.intensity === 0 ? item.intensity : item.intensity || 1;
          light = new HemisphereLight(skyColor, groundColor, intensity);
          if (item.position) {
            light.position.copy(item.position);
          }
        }
        this.allLights.push(light);
        this.scene.add(light);
      });
    },
    // функция обновления управления камеры
    updateControls() {
      if (this.controlsOptions) {
        Object.assign(this.controls, this.controlsOptions);
      }
    },
    // функция загрузки модели
    load() {
      // если нет ссылки останавливаем
      if (!this.src) return;
      // если у нас уже есть объект мы удаляем его из wrapper
      if (this.object) {
        this.wrapper.remove(this.object);
      }
      // устанавливаем параметры загрузки модели
      this.loader.setRequestHeader(this.requestHeader || {});
      this.loader.load(
        this.src,
        (...args) => {
          const object = this.getObject(...args);
          // непонятно зачем
          if (this.process) {
            this.process(object);
          }
          // вызываем функцию добавления полученного объекта
          this.addObject(object);
          this.$emit("on-load");
        },
        (xhr) => {
          // процесс загрузки
          this.$emit("progress", xhr);
        },
        (err) => {
          // ошибка
          this.$emit("on-error", err);
        },
      );
    },
    // ??
    getObject(object) {
      return object;
    },
    // функция добавления объекта
    addObject(object) {
      // получаем центр объекта
      const center = this.getCenter(object);
      // корректируем позицию враппера
      this.wrapper.position.copy(center.negate());
      // присваиваем модель в object
      this.object = object;
      // передаем в родителя размеры компонента
      this.$emit("getResize", this.size);
      // добавляем объект во wrapper
      this.wrapper.add(object);
      // обновляем камеру и положение модели
      this.updateCamera();
      this.updateModel();
      // создаем коробку по габаритам объекта
      // устанавливаем отдаление камеры в зависимости от размера объекта
      const box = new Box3().setFromObject(object);
      const size = new Vector3();
      const boxSize = box.getSize(size);
      const maxDim = Math.max(boxSize.x, boxSize.y, boxSize.z);
      const angle = (180 - this.camera.fov) / 2;
      const z = maxDim * Math.sin((angle * Math.PI) / 180.0);
      this.camera.position.z = z * 1.5 + Math.min(boxSize.x, boxSize.y, boxSize.z);
      // находим центр
      box.getCenter(object.position);
      object.position.multiplyScalar(-1);
      // создаем новую группу добавляем на сцену и задаем вращение
      this.pivot = new Group();
      this.scene.add(this.pivot);
      this.pivot.rotation.y = -3.14 / 6;
      this.pivot.rotation.x = 3.14 / 6;
      // Если тип gltf
      this.groups[0] = {
        surfaces: [],
      };
      if (this.type === "gltf") {
        // проходимся по всем нодам сцены
        this.scene.traverse((child) => {
          // если тип mesh - закидываем в surfaces и обрабатываем элемент
          if (child.type === "Mesh") {
            this.groups[0].surfaces.push(child[this.surfaceId]);
            this.groups[0].color = 0xbfbfbf;
            this.expandObject(child);
          }
        });
        this.$store.commit("model/setGroups", this.groups);
        // возвращаем в родителя обхект поверхностей
        this.$emit("surfaces", {
          surfaces: this.surfaces,
        });
        // добавляем в группу
        this.pivot.add(object);
        // если тип stl
      } else if (this.type === "stl") {
        // создаем грани
        const edgesGeometry = new EdgesGeometry(object.geometry, 15);
        const edges = new LineSegments(
          edgesGeometry,
          new LineBasicMaterial({
            color: 0x1b3740,
            linewidth: 1,
            linecap: "round",
            linejoin: "round",
          }),
        );
        edges.position.x = this.wrapper.position.x;
        edges.position.y = this.wrapper.position.y;
        edges.position.z = this.wrapper.position.z;
        // добавляем объект и грани в группу
        this.pivot.add(object);
        this.pivot.add(edges);
      }
      // возвращаем что загрузка закончилась
      this.$emit("loading", false);
      this.$store.commit("modelV2/setModelReady", true);
    },
    // функция вызываемая при раскрыти разбитой gltf модели
    expandObject(object) {
      const expandedObject = object;
      // исправляем возможные проблемы материалов
      expandedObject.material.depthWrite = true;
      expandedObject.material.polygonOffset = true;
      expandedObject.material.polygonOffsetFactor = 1;
      expandedObject.material.polygonOffsetUnits = 1;
      expandedObject.material.metalness = 0.3;
      expandedObject.material.color.set(0xbfbfbf);
      expandedObject.geometry.getIndex = () => expandedObject.geometry.index;
      this.surfaces[expandedObject[this.surfaceId]] = {};
      this.surfaces[expandedObject[this.surfaceId]].mesh = expandedObject;
      this.surfaces[expandedObject[this.surfaceId]].group = 0;
      // создаем грани для объектов
      const edgesGeometry = new EdgesGeometry(expandedObject.geometry, 15);
      const edges = new LineSegments(
        edgesGeometry,
        new LineBasicMaterial({
          color: 0x1b3740,
          linewidth: 1,
          linecap: "round",
          linejoin: "round",
        }),
      );
      edges.position.x = this.wrapper.position.x;
      edges.position.y = this.wrapper.position.y;
      edges.position.z = this.wrapper.position.z;
      // ищем позиции
      if (Object.keys(this.positions).length === 0) {
        this.positions = this.findPosition(this.scene);
      }
      edges.scale.x = this.positions.scale.x;
      edges.scale.y = this.positions.scale.y;
      edges.scale.z = this.positions.scale.z;
      edges.rotation.x = this.positions.rotation.x;
      edges.rotation.y = this.positions.rotation.y;
      edges.rotation.z = this.positions.rotation.z;
      // добавляем в группу
      this.pivot.add(edges);
    },
    colorizeModel(data) {
      data.groups.forEach((group, index) => {
        if (group.name !== "all") {
          this.groups[group.name].surfaces.forEach((surface) => {
            if (surface && this.surfaces[surface]) {
              const color = data.colors[index];
              this.surfaces[surface].mesh.material.color.setRGB(color.r, color.g, color.b);
            }
          });
        } else {
          Object.keys(this.surfaces).forEach((surface) => {
            if (this.surfaces[surface].group === 0) {
              const color = data.colors[index];
              this.surfaces[surface].mesh.material.color.setRGB(color.r, color.g, color.b);
            }
          });
        }
      });
    },
    decorateSurfaces(surfaces) {
      for (const item of Object.keys(this.surfaces)) {
        const index = surfaces.findIndex((el) => el === item);
        if (index >= 0) {
          this.surfaces[item].mesh.material.color.setRGB(
            this.clickColor.r,
            this.clickColor.g,
            this.clickColor.b,
          );
        } else {
          this.surfaces[item].mesh.material.color.setRGB(
            this.hideColor.r,
            this.hideColor.g,
            this.hideColor.b,
          );
        }
      }
    },
    decorateSurfacesV2(groups) {
      const decoratedSurfaces = [];
      let allGroup = null;
      for (const group of groups) {
        if (group.surfaces !== "all") {
          for (const item of Object.keys(this.surfaces)) {
            const index = group.surfaces.findIndex((el) => el === item);
            if (index >= 0) {
              decoratedSurfaces.push(item);
              this.surfaces[item].mesh.material.color.setRGB(
                group.color.r,
                group.color.g,
                group.color.b,
              );
            }
          }
        } else {
          allGroup = group;
        }
      }
      for (const item of Object.keys(this.surfaces)) {
        const index = decoratedSurfaces.findIndex((el) => el === item);
        if (index < 0) {
          if (allGroup) {
            this.surfaces[item].mesh.material.color.setRGB(
              allGroup.color.r,
              allGroup.color.g,
              allGroup.color.b,
            );
          } else {
            this.surfaces[item].mesh.material.color.setRGB(
              this.hideColor.r,
              this.hideColor.g,
              this.hideColor.b,
            );
          }
        }
      }
    },
    decorateOneSurface(data) {
      if (this.surfaces[data.surface]) {
        this.surfaces[data.surface].mesh.material.color.setRGB(
          data.color.r,
          data.color.g,
          data.color.b,
        );
      }
    },
    clearSurfaces() {
      Object.keys(this.surfaces).forEach((surface) => {
        this.surfaces[surface].mesh.material.color.setRGB(
          this.hideColor.r,
          this.hideColor.g,
          this.hideColor.b,
        );
      });
    },
    clearModel() {
      Object.keys(this.surfaces).forEach((surface) => {
        this.surfaces[surface].mesh.material.color.set(this.groups[0].color);
      });
    },
    deleteGroup(id) {
      if (id !== null) {
        this.groups[id].surfaces.forEach((surface) => {
          if (surface && this.surfaces[surface]) {
            this.surfaces[surface].group = 0;
          }
        });
        delete this.groups[String(id)];
        this.$emit("set-group", this.groups);
      }
    },
    colorGroups(groups) {
      groups.forEach((v) => {
        if (this.groups[v]) {
          this.groups[v].surfaces.forEach((item) => {
            this.surfaces[item].mesh.material.color.set(this.groups[v].color);
          });
        }
      });
    },
    // функция нужна для поиска поворота и масштаба исходной модели
    findPosition(object) {
      if (
        object.rotation &&
        object.rotation.x === 0 &&
        object.rotation.y === 0 &&
        object.rotation.z === 0
      ) {
        if (object.children[0]) {
          return this.findPosition(object.children[0]);
        } else {
          return {
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            scale: {
              x: 1,
              y: 1,
              z: 1,
            },
          };
        }
      }
      return object;
    },
    // функция анимации
    animate() {
      this.reqId = requestAnimationFrame(this.animate);
      this.controls.update();
      this.render();
    },
    // функци рендера
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    clearScene() {
      this.scene = new Scene();
      this.object = null;
      this.raycaster = new Raycaster();
      this.mouse = new Vector2();
      this.camera = new PerspectiveCamera(45, 1, 0.01, 100000);
      this.wrapper = new Object3D();
      // при завершении компонента
      cancelAnimationFrame(this.reqId);
      // убираем сцену
      // this.scene.dispose();
      // убираем рендер
      this.renderer.dispose();
      // убираем управление
      if (this.controls) {
        this.controls.dispose();
      }
      //
    },
    initScene() {
      // передаем события, начала работы компонента
      this.$emit("loading", true);
      // Если ширина и высота не были переданы, задаем габариты компонента
      if (this.width === undefined || this.height === undefined) {
        this.size = {
          width: this.$el.offsetWidth,
          height: this.$el.offsetHeight,
        };
      }
      // создаем переменную опций для webgl
      const options = { ...DEFAULT_GL_OPTIONS, ...this.glOptions, canvas: this.$refs.canvas };
      // задаем объект рендера и настраиваем параметры
      this.renderer = new WebGLRenderer(options);
      this.renderer.shadowMap.enabled = true;
      this.renderer.outputEncoding = this.outputEncoding;
      // создаем управление камерой и задаем параметры
      this.controls = new TrackballControls(this.camera, this.$el);
      this.controls.panSpeed = 0.5;
      this.controls.rotateSpeed = 5;
      this.controls.zoomSpeed = 2;
      this.controls.dynamicDampingFactor = 0.3;
      // добавляем пустой 3д объект на сцену
      this.scene.add(this.wrapper);
      // вызываем функцию загрузки
      this.load();
      // вызываем функцтю обновления
      this.update();
      // создаем лисенеры событий нажатие и отпускание мыши и изменение размера
      this.$el.addEventListener("pointerup", this.onMouseUp, false);
      this.$el.addEventListener("pointerdown", this.onMouseDown, false);
      window.addEventListener("resize", this.onResize, false);
      this.$el.addEventListener(
        "resize",
        (e) => {
          console.log(e, "el resize");
        },
        false,
      );
      this.$el.addEventListener(
        "touchend",
        (e) => {
          e.preventDefault();
          this.controls.dispose();
          this.controls = new TrackballControls(this.camera, this.$el);
          this.controls.panSpeed = 0.5;
          this.controls.rotateSpeed = 5;
          this.controls.zoomSpeed = 2;
          this.controls.dynamicDampingFactor = 0.3;
        },
        false,
      );
      // вызываем функцию анимации
      this.animate();
      // создаем объект для эффектов (не используется)
      this.composer = new EffectComposer(this.renderer);
      this.$store.commit("model/setDeleteFunction", this.deleteGroup);
      this.$store.commit("model/setColorizeFunction", this.colorizeModel);
      this.$store.commit("model/setClearFunction", this.clearModel);
      this.$store.commit("model/setColorGroupFunction", this.colorGroups);
      this.$store.commit("model/setDecorateSurfaces", this.decorateSurfaces);
      this.$store.commit("modelV2/setDecorateSurfaces", this.decorateSurfacesV2);
      this.$store.commit("modelV2/setDecorateOneSurface", this.decorateOneSurface);
      this.$store.commit("modelV2/setClearSurfaces", this.clearSurfaces);
    },
  },
  created() {
    // для перевода в полноэкранный режим, родителю при создании этого компонента
    // передается функция onResize, для пересчета размера картинки
    this.$parent.$on("fullsize", this.onResize);
  },
  mounted() {
    this.initScene();
  },
  beforeDestroy() {
    // при завершении компонента
    cancelAnimationFrame(this.reqId);
    // убираем сцену
    // this.scene.dispose();
    // убираем рендер
    this.renderer.dispose();
    // убираем управление
    if (this.controls) {
      this.controls.dispose();
    }
    // убираем лисенеры
    this.$el.removeEventListener("pointerdown", this.onMouseDown, false);
    this.$el.removeEventListener("pointerup", this.onMouseUp, false);
    window.removeEventListener("resize", this.onResize, false);
    // ???
    this.outlinePass = new OutlinePass(
      new Vector2(window.innerWidth, window.innerHeight),
      this.scene,
      this.camera,
    );
  },
};
</script>
